import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EventKind } from '@rallycry/api-suite-typescript'
import Color from 'color'
import { useEffect, useState } from 'react'
import { first } from 'lodash-es'
import { EntrantState } from '@rallycry/social-api-typescript'
import { usePathname } from 'next/navigation'
import { LadderScheduler } from './LadderScheduler'
import { Countdown } from '@/components/molecules/text/Countdown'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { swirlySx } from '@/core/animations'
import { useNavigation } from '@/core/hooks/useNavigation'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { toBase64 } from '@/core/toBase64'
import { useBracket } from '@/entity/bracket/useBracket'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useMatchmEntry } from '@/entity/matchm/useMatchmEntry'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { useCompetitionTeamsIncludingIssues } from '@/entity/competition-team/useCompetitionTeams'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { TeamAvatar } from '@/components/organisms/avatar/TeamAvatar'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { calcElevationString } from '@/style/palette'
import { useSessionStorage } from '@/core/hooks/useLocalStorage'
import { RcIcon } from '@/components/atoms/RcIcon'

export const LadderBanner = () => {
  const { competition, isCompetitionModerator } = useCompetition()

  const { teams } = useCompetitionTeamsIncludingIssues({
    selfOnly: true,
    request: { competitionId: competition?.id }
  })

  const [team, setTeam] = useSessionStorage({
    key: 'ladder-banner-team',
    defaultValue: first(teams)
  })
  const [actioned, setActioned] = useState(false)
  const [showSchedulerModal, setShowSchedulerModal] = useState(false)

  const { navTo } = useNavigation()
  const theme = useTheme()

  const { events, handleEventUpdates } = useCompetitionEvents()
  const { bracket } = useBracket()
  const path = usePathname()
  const isLadderPath = path.includes(
    `${CompetitionRoute.Bracket}/${bracket?.id}`
  )
  const { enter, leave, matchm, viewMatchDetails } = useMatchmEntry({
    idOrKey: `${bracket?.externalId}_${team?.id}`
  })

  const queued = matchm?.state === EntrantState.ENTERED
  const hasMatch = matchm?.state === EntrantState.MATCHELECTED

  useEffect(() => {
    setActioned(false)
  }, [queued])

  const ladderEvents = events([EventKind.LADDER])
  const ladderActive = !!ladderEvents.current
  const nextLadder = ladderEvents.next
  const current = ladderEvents.current
  const handleMatchNav = async () => {
    setActioned(true)
    try {
      const detail = await viewMatchDetails(matchm?.matchElected!)
      navTo({
        root: RootRoute.Competition,
        rootId: detail._expanded?.competition?.[0]?.id,
        subRoute: CompetitionRoute.Match,
        subId: detail?.id
      })
    } finally {
      setActioned(false)
    }
  }

  const image = `url(data:image/svg+xml;base64,${toBase64(
    borderBox(
      Color(theme.palette.primary.light).alpha(0.5).rgb().string(),
      Color(theme.palette.primary.light).alpha(0.1).rgb().string()
    )
  )})`

  const options: ActionMenuOption[] = teams?.map(team => ({
    key: team.id?.toString()!,
    display: team.alternateName,
    action: async () => setTeam(team)
  }))

  return (
    <Card
      sx={theme => ({
        backgroundImage: calcElevationString(theme.palette.mode, [0]),
        mx: { xs: 0 },
        borderRadius: { xs: 0, sm: 2 }
      })}
    >
      {teams.length > 1 ? (
        <Stack
          direction='row'
          justifyContent='space-between'
          py={2}
          pl={2}
          pr={5}
        >
          <ActionMenu
            options={options}
            buttonProps={{ size: 'small', sx: { mr: 2 } }}
            icon={['fal', 'chevron-down']}
          >
            <Stack direction='row' alignItems='center' spacing={2}>
              <TeamAvatar team={team} modal size='xs' skipLink />
              <RcIcon icon={['fal', 'chevron-down']} />
            </Stack>
          </ActionMenu>
          {isLadderPath ? null : (
            <RcIconButton
              size='small'
              icon={['fal', 'chevron-right']}
              onClick={() =>
                navTo({
                  root: RootRoute.Competition,
                  rootId: competition?.id,
                  subRoute: CompetitionRoute.Bracket,
                  subId: bracket?.id
                })
              }
            />
          )}
        </Stack>
      ) : null}
      <Card sx={{ mx: { xs: 0 }, borderRadius: { xs: 0, sm: 2 } }}>
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            backgroundColor: 'primary.dark',
            backgroundImage: ladderActive ? 'url(/dust.svg)' : undefined,
            backgroundBlendMode: 'overlay',
            py: 3,

            transition: 'opacity .3s',
            ...swirlySx
          }}
        >
          <Grid
            container
            direction='row'
            alignItems='stretch'
            spacing={5}
            sx={{ padding: 7 }}
          >
            <Grid
              item
              xs={12}
              sm
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                opacity: ladderActive ? 1 : 0.7,
                '&:before': {
                  content: { xs: 'none', sm: '""' },
                  position: 'absolute',
                  width: 1000,
                  height: 'calc(100% + 14px)',
                  backgroundImage: image,
                  backgroundSize: 'cover',
                  right: -50,
                  transform: 'rotate(180deg)'
                }
              }}
            >
              <Box sx={{ opacity: 0.99, width: '90%' }}>
                {hasMatch ? (
                  <SectionHeader
                    title={
                      <RcTrans i18nKey='competition:bracket.ladder.match-found'>
                        Match found!
                      </RcTrans>
                    }
                    subtitle={
                      <RcTrans i18nKey='competition:bracket.ladder.match-found-desc'>
                        Contact your opponent to get started and then report
                        some scores.
                      </RcTrans>
                    }
                  />
                ) : queued ? (
                  <SectionHeader
                    title={
                      <RcTrans i18nKey='competition:bracket.ladder.in-queue-label'>
                        In Queue For
                      </RcTrans>
                    }
                    subtitle={
                      <Stack>
                        <Countdown countUp target={new Date()} />
                      </Stack>
                    }
                  />
                ) : ladderActive ? (
                  <SectionHeader
                    title={
                      <Stack direction='row' spacing={1}>
                        <Typography variant='h2'>
                          <RcTrans i18nKey='competition:bracket.ladder.open-queue-label'>
                            Queue is open for
                          </RcTrans>
                        </Typography>
                        <Countdown
                          target={current?.endDate || nextLadder?.startDate}
                        />
                      </Stack>
                    }
                  />
                ) : (
                  <SectionHeader
                    title={
                      <RcTrans i18nKey='competition:bracket.ladder.queue-closed-label'>
                        Queue is closed
                      </RcTrans>
                    }
                    subtitle={
                      <RcTrans i18nKey='competition:bracket.ladder.queue-closed-desc'>
                        Check out the full schedule to find out more
                      </RcTrans>
                    }
                  />
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Button
                  disabled={actioned}
                  onClick={
                    hasMatch
                      ? () => handleMatchNav()
                      : queued
                      ? async () => {
                          setActioned(true)
                          try {
                            await leave(matchm)
                          } finally {
                            setActioned(false)
                          }
                        }
                      : ladderActive
                      ? async () => {
                          setActioned(true)
                          try {
                            await enter(bracket?.externalId!, team?.id!)
                          } finally {
                            setActioned(false)
                          }
                        }
                      : () =>
                          isCompetitionModerator
                            ? setShowSchedulerModal(true)
                            : navTo({
                                root: RootRoute.Competition,
                                rootId: competition?.id,
                                subRoute: CompetitionRoute.Info,
                                subId: CompetitionInfoRoute.Details,
                                query: 'calendarScrollTo=true'
                              })
                  }
                  color='primary'
                  size='extraLarge'
                  sx={theme => ({
                    width: hasMatch
                      ? 130
                      : queued
                      ? 94
                      : ladderActive
                      ? 65
                      : 150,
                    transition: 'width .3s',
                    borderRadius: 100,
                    color: theme.palette.getContrastText(
                      theme.palette.primary.dark
                    )
                  })}
                >
                  {hasMatch
                    ? 'View Match'
                    : queued
                    ? 'Leave'
                    : ladderActive
                    ? 'Join'
                    : 'View Schedule'}
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                opacity: ladderActive ? 1 : 0.7,
                transform: 'rotate(0)',
                '&:before': {
                  content: { xs: 'none', sm: '""' },
                  position: 'absolute',
                  width: 1000,
                  height: 'calc(100% + 14px)',
                  backgroundImage: image,
                  backgroundSize: 'cover',
                  pointerEvents: 'none',
                  left: -30
                }
              }}
            >
              <Box
                sx={{
                  minWidth: 100
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <PreventParentClick>
          <ModalTrigger
            open={showSchedulerModal}
            onClose={() => setShowSchedulerModal(false)}
            modalProps={{
              maxWidth: 'md',
              noPadding: true,
              noHeader: true,
              noFooter: true
            }}
          >
            {({ handleClose }) => (
              <LadderScheduler
                existing={ladderEvents.all}
                onComplete={(creates, updates, removals) =>
                  handleEventUpdates(creates, updates, removals, handleClose)
                }
              />
            )}
          </ModalTrigger>
        </PreventParentClick>
      </Card>
    </Card>
  )
}

export const borderBox = (border: string, background: string) => `
<svg width="4965" height="162" viewBox="0 0 4965 162" fill="none" xmlns="http://www.w3.org/2000/svg">

  <g clip-path="url(#inner)">
    <path opacity="0.9"
      d="M17.0935 1H4966V161H17.0935C11.4861 161 10.1272 153.186 15.4055 151.294L25.3035 147.744C40.3821 142.336 52.8694 131.445 60.2756 117.241C72.1174 94.5313 72.1174 67.4687 60.2756 44.7589C52.8694 30.5549 40.3821 19.6636 25.3035 14.2561L15.4055 10.7065C10.1272 8.81361 11.4861 1 17.0935 1Z"
      fill="url(#gradient)" />
  </g>
  <g clip-path="url(#outer)">
    <path opacity="0.9"
      d="M17.0935 1H4966V161H17.0935C11.4861 161 10.1272 153.186 15.4055 151.294L25.3035 147.744C40.3821 142.336 52.8694 131.445 60.2756 117.241C72.1174 94.5313 72.1174 67.4687 60.2756 44.7589C52.8694 30.5549 40.3821 19.6636 25.3035 14.2561L15.4055 10.7065C10.1272 8.81361 11.4861 1 17.0935 1Z"
      stroke="url(#gradient2)" />
  </g>

  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color="transparent" />
      <stop offset="10%" stop-color="${background}" />
    </linearGradient>

    <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0.0" stop-color="rgba(255,255,255,.7)" />
      <stop offset=".05" stop-color="${border}" />
      <stop offset=".6" stop-color="transparent" />
    </linearGradient>


    <clipPath id="inner">
      <rect width="4965" height="162" fill="white" />
    </clipPath>

    <clipPath id="outer">
      <rect width="4965" height="162" fill="white" />
    </clipPath>
  </defs>
</svg>
`
